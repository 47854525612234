class BackToTop {
  constructor(target = '#backtotop') {
    this.target = document.querySelector(target);
    this.loaded = 0;
    this.update();
  }

  update() {
    if (this.target) {
      window.scrollY >= 100 ? this.show() : this.hide();
      this.loaded ? '' : this.events();
      this.loaded = 1;
    }
  }

  events() {
    window.addEventListener('scroll', () => {
      this.update();
    });
  }

  hide() {
    this.target.classList.add('backtotop--hidden');
  }

  show() {
    this.target.classList.remove('backtotop--hidden');
  }
}

export { BackToTop };
