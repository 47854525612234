import $ from 'jquery';

class TaskNav {
  constructor() {
    this.taskNav = document.getElementById('task-nav');
    this.btnShowSearch = document.querySelector('.campus-menu .js-show-search');
    this.searchBox = document.getElementById('campus-search-query');
    this.taskNavBtn = `<button class="task-nav-button" aria-label="Toggle Task Nav" data-toggle="task-nav">
    <svg aria-hidden="true" class="close" height="14" width="14" focusable="false" viewBox="0 0 32 32">
      <path fill="currentColor" d="M31.2,26.1c-1-1.1-5.2-5.4-10-10.1c4.8-4.9,9.2-9.3,10-10.1c2-2-2.9-7.3-5.1-5.1c-0.9,1-5.2,5.3-10.1,10.1 C11.2,6,6.7,1.6,5.8,0.7c-2-2-7.3,2.9-5.1,5.1C1.7,6.7,6.1,11.1,11,16c-4.9,4.9-9.3,9.2-10.2,10.2c-2.1,2.1,2.4,7.3,5.1,5.1 c1.1-1,5.5-5.3,10.2-10.1c4.8,4.8,9.1,9.1,10,10.1C28.3,33.3,33.5,28.8,31.2,26.1z"></path>
    </svg>
    <svg aria-hidden="true" class="open" width="22" height="14" focusable="false" viewBox="0 0 22 14">
      <rect fill="currentColor" width="22" height="2"></rect>
      <rect y="5.958" fill="currentColor" width="22" height="2"></rect>
      <rect y="12" fill="currentColor" width="22" height="2"></rect>
    </svg>
  </button>`;

    if (this.taskNav) {
      this.init();
    }
  }

  init() {
    this.checkCompatibility();
    this.createOverlay();

    if (this.btnShowSearch) {
      this.btnShowSearch.addEventListener('click', () => {
        setTimeout(() => {
          this.searchBox.focus();
        }, 300);
      });
    }

    this.taskNav.addEventListener('focusout', (event) => {
      const togglerClass = this.taskNav.getAttribute('data-toggler');
      if (!this.taskNav.contains(event.relatedTarget) && this.taskNav.classList.contains(togglerClass)) {
        $('#task-nav').foundation('toggle');
      }
    });
  }

  checkCompatibility() {
    if (!this.taskNav.querySelectorAll('.task-nav-button').length) {
      this.makeCompatible();
    }
  }

  createOverlay() {
    const overlay = document.createElement('div');
    overlay.classList.add('task-nav-overlay');
    overlay.dataset.toggle = 'task-nav';
    this.taskNav.insertAdjacentElement('afterend', overlay);
  }

  makeCompatible() {
    const showSearchBtn = document.querySelector('.campus-menu .js-show-search');
    const oldButtons = document.querySelectorAll('.task-nav-toggle');

    this.taskNav.setAttribute('data-toggler', 'expanded');
    this.taskNav.insertAdjacentHTML('afterbegin', this.taskNavBtn);
    if (showSearchBtn) {
      showSearchBtn.setAttribute('data-toggle', 'task-nav');
    }
    oldButtons.forEach(element => element.parentNode.removeChild(element));
  }
}

export { TaskNav };
