import $ from 'jquery';

class Carousel {
  constructor(selector = '[data-slick]') {
    this.selector = selector;
    this.carousels = document.querySelectorAll(selector);
    this.slickCSS =
      'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.css';
    this.slickJS =
      'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.0/slick/slick.min.js';
    this.carouselButtonHtml = `<svg width="36" height="36" viewBox="0 0 36 36">
      <circle cx="17" cy="17" r="17" transform="translate(1 1)" fill="#fff"/>
      <path d="M23.248 10.006l-3.34-3.34L8.557 18.02l11.353 11.353 3.339-3.34-8.014-8.013 8.014-8.014z" fill="#333"/>
    </svg>`;
    this.slickOptions = {
      adaptiveHeight: true,
      nextArrow: `<button aria-label="Next" class="slick-next" type="button">${this.carouselButtonHtml}</button>`,
      prevArrow: `<button aria-label="Previous" class="slick-prev" type="button">${this.carouselButtonHtml}</button>`,
      focusOnChange: true,
      dots: true,
    };

    this.init();
  }

  init() {
    const jsExists =
      document.querySelector('script[src*="slick.js"]') ||
      document.querySelector('script[src*="slick.min.js"]');
    const cssExists = document.querySelector('link[href*="slick."]');

    if (this.carousels.length) {
      if (!cssExists) {
        this.addSlickCSS();
      }
      if (!jsExists) {
        this.addSlickJS();
      } else {
        this.runSlick();
      }
    }
  }

  addSlickCSS() {
    $('head').append(`<link rel="stylesheet" href="${this.slickCSS}">`);
  }

  addSlickJS() {
    $.when(
      $.getScript(this.slickJS).done(() => {
        this.runSlick();
      })
    );
  }

  runSlick() {
    // ? Do we still need this?
    $(this.carousels).on('init', () => {
      if ($('.banner .slick-dotted').length) {
        $('html').addClass('banner-dotted');
      }
    });

    $(this.carousels).slick(this.slickOptions);
  }
}

export { Carousel };
