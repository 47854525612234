'use strict';

import $ from 'jquery';

const Nest = {
  Feather(menu, type = 'zf') {
    menu.attr('role', 'menubar');
    menu.find('a').attr({ role: 'menuitem' });

    var items = menu.find('li').attr({ role: 'none' }),
      subMenuClass = `is-${type}-submenu`,
      subItemClass = `${subMenuClass}-item`,
      hasSubClass = `is-${type}-submenu-parent`,
      applyAria = type !== 'accordion'; // Accordions handle their own ARIA attriutes.

    items.each(function () {
      var $item = $(this),
        $sub = $item.children('ul');

      if ($sub.length) {
        $item.addClass(hasSubClass);
        if (applyAria) {
          $item.children('a:first').attr({
            'aria-haspopup': true,
            'aria-expanded': false,
          });
        }
        $sub.addClass(`submenu ${subMenuClass}`).attr({
          'data-submenu': '',
          role: 'menubar',
        });
        if (type === 'drilldown') {
          $sub.attr({ 'aria-hidden': true });
        }
      }

      if ($item.parent('[data-submenu]').length) {
        $item.addClass(`is-submenu-item ${subItemClass}`);
      }
    });

    return;
  },

  Burn(menu, type) {
    var //items = menu.find('li'),
      subMenuClass = `is-${type}-submenu`,
      subItemClass = `${subMenuClass}-item`,
      hasSubClass = `is-${type}-submenu-parent`;

    menu
      .find('>li, > li > ul, .menu, .menu > li, [data-submenu] > li')
      .removeClass(
        `${subMenuClass} ${subItemClass} ${hasSubClass} is-submenu-item submenu is-active`
      )
      .removeAttr('data-submenu')
      .css('display', '');
  },
};

export { Nest };
